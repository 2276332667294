<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增短信产品配置' : '编辑短信产品配置'" append-to-body width="700px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="产品编码" prop="productCode">
        <el-input v-model="form.productCode" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="产品名称" prop="productName">
        <el-input v-model="form.productName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="业务系统" prop="bsysCode">
        <el-select v-model="form.bsysCode" placeholder="请选择业务系统">
        <el-option
          v-for="item in bsysoptions"
          :key="item.bsysCode"
          :label="item.bsysName"
          :value="item.bsysCode">
        </el-option>
      </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { insertProduct, updateProduct } from '@/api/sms'
import { parseTime } from '@/utils/index'
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    bsysoptions: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      dialog: false, loading: false, form: {productId: '',productCode: '', productName: '', bsysCode: '', bsysName: ''},
      rules: {
        productCode: [
          { required: true, message: '请输入产品编码', trigger: 'blur' }
        ],
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        bsysCode: [
          { required: true, message: '请选择业务系统', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      this.formData = this.form
      this.params = JSON.stringify(this.formData)
      insertProduct(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      this.formData = this.form 
      this.params = JSON.stringify(this.formData)
      updateProduct(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = { productId: '',productCode: '', productName: '', bsysCode: '', bsysName: ''}
    }
  }
}
</script>

<style scoped>

</style>
