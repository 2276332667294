import request from '@/utils/request'

export function initData(url, params) {
  return request({
    url: url,
    method: 'post',
    data: params
  })
}

//业务系统 接口开始
export function insertBusinessSys(params) {
  return request({
    url: "/jrqsms/api/business/insertBusinessSys",
    method: 'post',
    data: params
  })
}

export function updateBusinessSys(params) {
  return request({
    url: "/jrqsms/api/business/updateBusinessSys",
    method: 'post',
    data: params
  })
}

export function listBusinessSys( params) {
  return request({
    url: "/jrqsms/api/business/listBusinessSys",
    method: 'post',
    data: params
  })
}

export function listAllBusinessSys( params) {
  return request({
    url: "/jrqsms/api/business/listAllBusinessSys",
    method: 'post',
    data: params
  })
}
//业务系统 接口结束


//外部短信平台 接口开始
export function insertPlatform(params) {
  return request({
    url: "/jrqsms/api/platform/insertPlatform",
    method: 'post',
    data: params
  })
}

export function updatePlatform(params) {
  return request({
    url: "/jrqsms/api/platform/updatePlatform",
    method: 'post',
    data: params
  })
}

export function listPlatform( params) {
  return request({
    url: "/jrqsms/api/platform/listPlatform",
    method: 'post',
    data: params
  })
}

export function listAllPlatform( params) {
  return request({
    url: "/jrqsms/api/platform/listAllPlatform",
    method: 'post',
    data: params
  })
}
//业务系统 接口结束


//签名管理 接口开始
export function insertSignInfo(params) {
  return request({
    url: "/jrqsms/api/sign/insertSignInfo",
    method: 'post',
    data: params
  })
}

export function updateSignInfo(params) {
  return request({
    url: "/jrqsms/api/sign/updateSignInfo",
    method: 'post',
    data: params
  })
}

export function listSignInfo( params) {
  return request({
    url: "/jrqsms/api/sign/listSignInfo",
    method: 'post',
    data: params
  })
}

export function listAllSignInfo( params) {
  return request({
    url: "/jrqsms/api/sign/listAllSignInfo",
    method: 'post',
    data: params
  })
}
//签名管理 接口结束


//基础配置 接口开始
export function insertSmsConfig(params) {
  return request({
    url: "/jrqsms/api/smsConfig/insertSmsConfig",
    method: 'post',
    data: params
  })
}

export function updateSmsConfig(params) {
  return request({
    url: "/jrqsms/api/smsConfig/updateSmsConfig",
    method: 'post',
    data: params
  })
}

export function listSmsConfig( params) {
  return request({
    url: "/jrqsms/api/smsConfig/listSmsConfig",
    method: 'post',
    data: params
  })
}
//基础配置 接口结束


//模板管理 接口开始
export function insertTempInfo(params) {
  return request({
    url: "/jrqsms/api/temp/insertTempInfo",
    method: 'post',
    data: params
  })
}

export function updateTempInfo(params) {
  return request({
    url: "/jrqsms/api/temp/updateTempInfo",
    method: 'post',
    data: params
  })
}

export function listTempInfo( params) {
  return request({
    url: "/jrqsms/api/temp/listTempInfo",
    method: 'post',
    data: params
  })
}
//模板管理 接口结束


//产品配置信息 接口开始
export function insertProduct(params) {
  return request({
    url: "/jrqsms/api/product/insertProduct",
    method: 'post',
    data: params
  })
}

export function updateProduct(params) {
  return request({
    url: "/jrqsms/api/product/updateProduct",
    method: 'post',
    data: params
  })
}

export function listProduct( params) {
  return request({
    url: "/jrqsms/api/product/listProduct",
    method: 'post',
    data: params
  })
}

export function listAllProduct( params) {
  return request({
    url: "/jrqsms/api/product/listAllProduct",
    method: 'post',
    data: params
  })
}
//产品配置信息 接口结束


//短信请求历史 接口开始
export function getUnsentSmsInfoList( params) {
  return request({
    url: "/jrqsms/api/unsentSmsInfo/getUnsentSmsInfoList",
    method: 'post',
    data: params
  })
}
//短信请求历史 接口结束


//短信请求历史 接口开始
export function getSmsInfoList( params) {
  return request({
    url: "/jrqsms/api/smsInfo/getSmsInfoList",
    method: 'post',
    data: params
  })
}
//短信请求历史 接口结束
