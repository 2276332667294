<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form"  :sup_this="sup_this" :is-add="false" :bsysoptions="bsysoptions"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    // index.vue 的this 可用于刷新数据
    sup_this: {
      type: Object,
      required: true
    },
    bsysoptions: {
      type: Array,
      required: true
    },
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form ={ productId:this.data.productId,productCode: this.data.productCode, productName: this.data.productName, bsysCode: this.data.bsysCode, bsysName: this.data.bsysName},
      _this.bsysoptions=this.bsysoptions
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{display: inline-block;margin-right: 3px;}
</style>
